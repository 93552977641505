import React from "react"
import * as styles from "./404.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.notfound}>
      <h1>The page you're looking for can't be found.</h1>
      <a href={"/"}>Click here to return home</a>
    </div>
  </Layout>
)

export default NotFoundPage
